@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.popup {
  @include flex(center, center);
  position: fixed;
  top: 1rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9000;

  &__container {
    @include card;
    margin: $mobile-padding-default;
    height: 70%;
    overflow-y: auto;
  }

  &__card {
    @include card;
    height: 100%;
    background: $color-primary-teal;
    z-index: 9000;

    &--highlight {
      background: $color-secondary-orange;
    }
  }

  &__header {
    @include flex(center, center);
    flex-direction: column;
    font-family: "Bauhaus";
    font-weight: 300;
    font-size: 1.5rem;
    color: $color-primary-white;
  }

  &__image {
    margin: 0.75rem auto;
    height: 10rem;
  }

  &__subheader {
    color: $color-primary-white;
  }

  &__inventory {
    @include direction(row);
    overflow-x: auto;
  }

  &__inventory-item {
    @include flex(end, center);
    flex-direction: (column);
    position: relative;
    margin: 0 0.25rem;
    padding: 0.5rem;

    // &:hover .popup__inventory-description {
    //   display: block;
    // }
  }

  &__inventory-image {
    width: 6.5rem;
    height: auto;
    max-height: 5rem;
  }

  &__inventory-name {
    @include mobile-paragraph-fontstyle;
    font-weight: 600;
    text-align: center;
    color: $color-primary-white;
  }

  &__inventory-description {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    border: 1px solid $color-primary-teal;
    border-radius: 0.25rem;
    padding: 0.5rem;
    transform: translateX(-50%);
    background: $color-primary-white;
    white-space: nowrap;
  }

  &__form {
    width: 100%;

    &-fields {
      @include direction(column);
      align-items: start;
      width: 100%;

      &--flex {
        @include flex(space-between, center);
        gap: 0.75rem;
      }

      &--small {
        max-width: 25%;
      }
    }

    &-label {
      @include label;
      color: $color-primary-white;
    }

    &-input {
      @include input;
      font-family: "TT Drugs";
      color: $color-primary-black;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $color-primary-white inset;
        box-shadow: 0 0 0px 1000px $color-primary-white inset;
        -webkit-text-fill-color: $color-primary-black !important;
        font-family: "TT Drugs";
      }

      &--dropdown {
        border-right: 7px solid $color-primary-white;
        padding-left: 0.75rem;
      }
    }

    &-button {
      @include button;
      display: block;
      margin: 1rem auto;
    }

    &--success,
    &--error {
      @include form-message;
    }
  }

  &__add-item {
    padding-top: 0.5rem;
  }

  &__add-item,
  &__clear-item {
    @include direction(row);
    @include mobile-paragraph-fontstyle;
    font-weight: 600;
    cursor: pointer;
    color: $color-primary-white;
  }

  &__button {
    @include button;
    align-self: center;
    margin: 1rem 0 0;

    &:disabled {
      background: #cccccc;
      cursor: not-allowed;
    }
  }

  &__close {
    position: absolute;
    top: $mobile-padding-default;
    right: $mobile-padding-default;
    border: none;
    font-size: 1.125rem;
    font-weight: 600;
    background: none;
    color: $color-primary-white;
    cursor: pointer;
    z-index: 1000;
  }

  &__flex-container {
    display: flex;
    gap: 1rem;

    &--space-between {
      justify-content: space-between;
    }
  }

  &__error {
    @include form-message;
    color: $color-primary-orange;
  }
}
