@use "./variables" as *;
@use "./typography" as *;

@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin mobile-paragraph-fontstyle {
  margin: 0.3rem 0;
  font-size: 0.75rem;
  font-weight: 400;

  @include tablet {
    font-size: 0.85rem;
  }
}

@mixin flex($justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin direction($direction) {
  display: flex;
  flex-direction: $direction;
}

@mixin scroll-button {
  &--visible {
    position: fixed;
    right: $mobile-margin-default;
    bottom: 1.25rem;
    border-radius: 50%;
    padding: 0.25rem;
    font-size: 1.75rem;
    color: $color-primary-white;
    background-color: $color-primary-teal;
    cursor: pointer;
    z-index: 9000;
  }

  &--hidden {
    display: none;
  }
}

@mixin card {
  @include flex(center, center);
  flex-direction: column;
  position: relative;
  border-radius: $card-border-radius;
  padding: $mobile-margin-default;
  height: 25rem;
  background-color: $color-secondary-background-overlay;
}

@mixin label {
  @include mobile-paragraph-fontstyle;
  margin-top: 0.75rem;
}

@mixin input {
  outline: none;
  border: 1.5px solid $color-primary-white;
  border-radius: $element-border-radius;
  padding: 0.5rem;
  width: 100%;
}

@mixin button {
  border-radius: $element-border-radius;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: white;
  background-color: $color-primary-orange;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0 0 0.25rem rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0.5px);
  }
}

@mixin form-message {
  margin: 0.5rem 0 0;
  font-size: 0.75rem;
  text-align: center;
}

@mixin icons {
  display: block;
  width: $icon-width;

  &--hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    transform: translateY(0.5px);
  }
}
