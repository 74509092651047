@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.footer {
  padding: $mobile-margin-default;
  min-height: $mobile-header-height;
  width: 100%;
  background-color: $color-primary-teal;

  &__container {
    @include flex(space-between, center);
  }

  &__logo {
    display: block;
    width: 2.5rem;
  }

  &__socials {
    @include flex(end, end);
    gap: 0.35rem;
    width: 50%;
  }

  &__contacts {
    @include direction(row);
    gap: 0.75rem;

    @include tablet {
      gap: 1rem;
    }
  }

  &__contact {
    margin: $mobile-margin-default 0 0;

    &-icon {
      max-width: 1.25rem;
      cursor: pointer;
    }

    &--flex {
      @include direction(row);
      gap: 0.75rem;
      max-width: 7rem;

      @include tablet {
        max-width: none;
      }
    }

    h5 {
      text-decoration: 2px underline $color-secondary-orange;
      font-family: "Bauhaus";
      font-size: 0.95rem;
      font-weight: 400;
      line-height: 1.25rem;

      @include tablet {
        font-size: 1rem;
      }
    }

    p {
      @include mobile-paragraph-fontstyle;
      margin: 0;

      @include tablet {
        margin: 0.3rem 0;
      }
    }
  }

  &__copyright {
    @include mobile-paragraph-fontstyle;
    margin: $mobile-margin-default 0;
  }
}
