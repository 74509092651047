@font-face {
  font-family: "Bauhaus";
  src: url("../../assets/fonts/BauhausStd/BauhausStd-Light.ttf")
      format("truetype"),
    url("../../assets/fonts/BauhausStd/BauhausStd-Light.otf")
      format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bauhaus";
  src: url("../../assets/fonts/BauhausStd/BauhausStd-Medium.ttf")
      format("truetype"),
    url("../../assets/fonts/BauhausStd/BauhausStd-Medium.otf")
      format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bauhaus";
  src: url("../../assets/fonts/BauhausStd/BauhausStd-Demi.ttf")
      format("truetype"),
    url("../../assets/fonts/BauhausStd/BauhausStd-Demi.otf")
      format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bauhaus";
  src: url("../../assets/fonts/BauhausStd/BauhausStd-Bold.ttf")
      format("truetype"),
    url("../../assets/fonts/BauhausStd/BauhausStd-Bold.otf")
      format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Drugs";
  src: url("../../assets/fonts/TT-Drugs/TTDrugs-Light.ttf")
      format("truetype"),
    url("../../assets/fonts/TT-Drugs/TTDrugs-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Drugs";
  src: url("../../assets/fonts/TT-Drugs/TTDrugs-Regular.ttf")
      format("truetype"),
    url("../../assets/fonts/TT-Drugs/TTDrugs-Regular.otf")
      format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Drugs";
  src: url("../../assets/fonts/TT-Drugs/TTDrugs-Bold.ttf")
      format("truetype"),
    url("../../assets/fonts/TT-Drugs/TTDrugs-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
