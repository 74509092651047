@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.banner {
  border-top: 0.5px solid $color-primary-white;
  border-bottom: 0.5px solid $color-primary-white;
  padding: $mobile-padding-default;
  background-color: $color-primary-orange;

  &__services {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__service {
    position: relative;
    display: inline-block;
  }

  &__service-icon {
    &:hover {
      cursor: pointer;
    }
    @include icons;
  }

  &__service:hover &__service-icon--hover {
    opacity: 1;
  }

  &__description {
    @include mobile-paragraph-fontstyle;
    text-align: center;

    &--highlight {
      font-family: "Bauhaus";
      font-size: 0.875rem;
      font-weight: 400;
    }

    &--cta {
      margin: 0.5rem auto;
      text-align: center;
    }
  }
}
