@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.header {
  @include flex(space-between, center);
  position: sticky;
  top: 0;
  padding: $mobile-margin-default;
  width: 100%;
  min-height: $mobile-header-height;
  background-color: $color-primary-teal;
  z-index: 9999;

  // @include tablet {
  //   min-height: $tablet-header-height;
  // }

  &__right-nav,
  &__left-nav {
    @include flex(center, center);
  }

  &__logo {
    display: block;
    height: 1.35rem;

    @include tablet {
      height: 1.75rem;
    }
  }

  &__navbar {
    display: flex;
    justify-items: space-evenly;
    gap: 0.25rem;

    @include tablet {
      gap: 0.5rem;
    }

    li {
      @include flex(center, center);
      padding: 0;
      margin: 0;
    }
  }

  &__button {
    position: relative;
    border: none;
    background: none;
    text-transform: uppercase;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
    color: $color-primary-white;
    font-family: inherit;
    font-size: 0.725rem;
    cursor: pointer;
    padding: 0;
    margin: 0;

    @include tablet {
      font-size: 1rem;
    }

    &:focus,
    &:hover {
      font-weight: 600;
    }

    &:focus:after,
    &:hover:after {
      width: 100%;
      left: 0%;
    }

    &:after {
      content: "";
      pointer-events: none;
      bottom: -2px;
      left: 50%;
      position: absolute;
      width: 0%;
      height: 2px;
      background-color: $color-secondary-orange;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 400ms;
      transition-property: width, left;
    }
  }
}
