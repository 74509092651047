@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.hero {
  padding: $mobile-padding-default 1.25rem;
  background-color: #317ac5;
  background-image: url(../../assets/images/Clouds.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;

  @include tablet {
    background-size: 100%;
    background-position: bottom center;
  }

  &__card {
    @include card;
    background: none;
  }

  &__logo {
    margin-top: -2.5rem;
    width: 12.5rem;

    @include tablet {
      width: 14rem;
    }
  }

  &__header {
    margin: $mobile-margin-default 0 0;
    font-family: "Bauhaus";
    font-weight: 300;
  }

  &__slogan {
    margin-top: $mobile-margin-default;
    text-align: center;
    font-family: "Bauhaus";
    font-size: 1.25rem;
    font-weight: 300;

    @include tablet {
      font-size: 1.5rem;
    }
  }

  &__subheader {
    @include mobile-paragraph-fontstyle;
    margin: 0.75rem 0;
    text-align: center;
    font-style: italic;
    width: calc(100% + 2rem);
  }

  &__subscribe {
    &-container {
      display: flex;
      width: 75vw;
      max-width: 22rem;
      border-radius: $element-border-radius;
      overflow: hidden;
    }

    &-form {
      @include flex(center, center);
    }

    &-input {
      @include input;
      padding: 0.5rem 6.75rem 0.5rem 1rem;
      min-width: 100%;
      background-color: $color-primary-teal;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $color-primary-teal inset;
        box-shadow: 0 0 0px 1000px $color-primary-teal inset;
        -webkit-text-fill-color: $color-primary-white !important;
        font-family: "TT Drugs";
      }

      &::placeholder {
        color: $color-primary-white;
        font-size: 0.75rem;
      }

      &--success,
      &--error {
        @include form-message;
      }
    }

    &-button {
      @include button;
      align-self: center;
      position: relative;
      right: 6.35rem;
    }
  }

  &__socials {
    @include direction(row);
    gap: 0.25rem;
    margin-top: 1rem;

    @include tablet {
      gap: 0.5rem;
    }

    &-link {
      display: block;
      position: relative;
    }

    &-icon {
      @include icons;
    }

    &-link:hover &-icon--hover {
      opacity: 1;
    }
  }
}
