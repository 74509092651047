@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.contact {
  @include flex(left, center);
  padding: $mobile-padding-default 1.25rem;
  background-image: url(../../assets/images/Carvoeiro_bkg.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

  &__container {
    @include card;
    width: fit-content;
    height: fit-content;
  }
  &__card {
    @include card;
    backdrop-filter: blur(4px);
    background-color: $color-background-overlay;
  }

  &__header {
    @include flex(center, center);
    flex-direction: column;

    &-logo {
      width: $icon-width;
    }

    &-title {
      font-family: "Bauhaus";
      font-weight: 300;
      font-size: 1.5rem;
    }
  }

  &__form {
    width: 100%;

    &-fields {
      @include direction(column);
      width: 100%;

      &--flex {
        @include flex(space-between, center);
        gap: 0.75rem;
      }
    }

    &-label {
      @include label;
    }

    &-input {
      @include input;
      color: $color-primary-black;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $color-primary-white inset;
        box-shadow: 0 0 0px 1000px $color-primary-white inset;
        -webkit-text-fill-color: $color-primary-black !important;
        font-family: "TT Drugs";
      }
    }

    &-button {
      @include button;
      display: block;
      margin: 1rem auto;
    }

    &--success,
    &--error {
      @include form-message;
    }
  }
}
