@use "./styles/partials/mixins" as *;
@use "./styles/partials/variables" as *;
@use "./styles/partials/typography" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
  scrollbar-width: thin;
}

body,
html,
button,
input,
textarea {
  margin: 0;
  padding: 0;
  font-family: "TT Drugs", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: $color-primary-white;
}

a {
  text-decoration: none;
  color: $color-primary-white;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

img {
  display: block;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
