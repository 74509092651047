@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;

.merch {
  padding: $mobile-margin-default;
  background-color: $color-secondary-beige;
  text-align: center;
  color: $color-primary-black;

  &__header-container {
    margin: 2rem 0 0.75rem;
    padding: $mobile-padding-default;
  }

  &__header {
    font-family: "Bauhaus";
    font-size: 2rem;
    font-weight: 300;
  }

  &__subheader {
    @include mobile-paragraph-fontstyle;
    font-weight: 400;
  }

  &__button {
    @include button;
    margin: 0.5rem;
    background-color: $color-primary-teal;
  }

  &__photo {
    margin: $mobile-margin-default auto;
  }
}
