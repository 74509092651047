$mobile-breakpoint: 320px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

$color-primary-black: #232940;

$color-primary-white: #ffffff;
$color-primary-orange: #f1943c;
$color-primary-teal: #7ecbc9;

$color-secondary-beige: #fff7e9;
$color-secondary-orange: #f7af6a;

$color-background-overlay: rgba(255, 255, 255, 0.25);
$color-secondary-background-overlay: rgba(126, 203, 201, 0.25);

$mobile-margin-default: 0.75rem;
$mobile-padding-default: 1.25rem;

$mobile-header-height: 1.5rem;
$tablet-header-height: 3.5rem;

$card-border-radius: 2.75rem;
$element-border-radius: 0.85rem;

$icon-width: 2rem;
